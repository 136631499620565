import Axios from 'axios';
import { startLoading, errorLoading, gotLayers, gotLegend } from 'app/reducers/map/info';
import { call, put, select } from 'redux-saga/effects';
import { StoreState } from 'types';
import { ContactsOutlined } from '@material-ui/icons';
import userCompany from 'lib/go/users/company';

const FORMATS = {
  xml: 'text/xml',
  json: 'application/json',
};
const FILTERABLES = [
  'customer',
  'route',
  'zone',
];

async function fetchGeo(name: string, format: string, params: any) {
  params = {
    request: name,
    version: '1.1.1',
    format,
    ...params
  };
  const response = await Axios.get(API_URLS.wms, { params });

  const contentType = (response.headers['content-type'] || '').replace(';.+', '');
  if (contentType !== format) {
    throw new Error(`${name} returned wrong Content-Type (${contentType}; expected ${format})`);
  }

  return response.data;
}

type LegendItem = {
  layerName: string;
  title: string;
  rules: Array<{
    filter?: string;
    symbolizers: Array<Partial<Record<'Point' | 'Line' | 'Polygon', any>>>;
  }>;
};
type LegendInfo = {
  Legend: LegendItem[];
};

function fetchLegend(layers: string[], params: any ) {
  const promises = layers
    .filter(l => FILTERABLES.indexOf(l) >= 0)
    .map(async l => {
      // only show legends if format is image/png
      if (FORMATS.json === 'image/png') {
        const info: LegendInfo = await fetchGeo('GetLegendGraphic', FORMATS.json, { layer: l, ...params });
        return info.Legend.find(leg => leg.layerName === l);
      }
    });
  return Promise.all(promises);
}

export function* fetchLayersSaga() {
  yield put(startLoading());
  try {
    const token = yield select((state: StoreState) => state.auth.user === null ? null : state.auth.user.token);
    const access_token = API_URLS.wmstoken ? token : null;

    const capabilities: string = yield call(() => fetchGeo('GetCapabilities', FORMATS.xml, { access_token }));

    const layers: string[] = [];
    new DOMParser()
      .parseFromString(capabilities, 'text/xml')
      .querySelectorAll('Capability > Layer > Layer > Name')
      .forEach(l => l.textContent && layers.push(l.textContent.trim()));
    yield put(gotLayers(layers));
    const legend: LegendItem[] = yield call(() => fetchLegend(layers, { access_token }));
    const legendObject: Record<string, any> = {};
    legend.forEach(it => {
      if (it) {
        legendObject[it.layerName] = it;
      }
    });
    yield put(gotLegend(legend));
  } catch (e) {
    console.error(e);
    yield put(errorLoading(e));
  }
}
